<template>
  <page title="填写订单">
    
    <addr name="王刚" tel="13309300049">
      陕西省西安市雁塔区玉祥门世纪家园小区10栋2101
    </addr>
    <arrow-wrap class="mt-3" url="/buy-goods">
      <div class="flex items-center">
        <div class="flex-1 overflow-hidden flex">
          <cube-scroll direction="horizontal" class="scroll-x">
            <list class="wrap-x">
              <router-link 
                :to="`/goods-detail/${item.id}`" 
                class="scroll-item mr-2" 
                v-for="(item,index) in TestData.videoList" 
                :key="index"
              >
                <media :src="item.image" ratio="3/4"  width="70" ></media>
              </router-link>
            </list>
          </cube-scroll>
          
        </div>
        <div class="text-xs text-gray-light ml-2">
          共 <b class="text-red-500">{{TestData.videoList.length}}</b> 件
        </div>
      </div>
    </arrow-wrap>
    <div class="mt-3">
      <cell note="¥1190.00" note-class="text-red-500" no-arrow>商品金额</cell>
      <cell note="包邮">运费</cell>
      <cell note="立减30" @click="useCoupon">优惠券</cell>
      <cell subtitle="可用积分3000 抵扣300" no-arrow no-hairline>
        积分抵扣
        <div slot="right">
          <cube-switch v-model="useScore" @input="useMyScore"></cube-switch>
        </div>
      </cell>
    </div>

    <div class="h-12 pl-4 bg-gray-50 relative hairline-top  flex items-center" slot="footer">
      <div class="flex-1 flex items-center ">
        合计：<span class="text-lg font-medium text-red-500">3920.00</span>
      </div>
      <btn color="red-500"  size="xl" radius="none"  @click="goPay" text-size="base">去支付</btn>
    </div>
  </page>
</template>

<script>
import { Addr } from "../../components";
import {mapGetters} from 'vuex'
export default {
  components: {
    Addr
  },
  data(){
    return{
      useScore: true
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  methods:{
    useCoupon() {
      console.log('优惠券');
    },
    useMyScore(e) {
      console.log(e);
    },
    goPay(){
      this.$router.push('/paid')
    }
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>